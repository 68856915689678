import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CheckMark from './images/check.png';
import XMark from './images/x.png';

const ExchangePage = ({ match, data, loading, strapiUrl }) => {
  const history = useHistory();
  const {
    params: { exchange },
  } = match;

  const [exchangedata, setExchangeData] = useState({
    attributes: {
      name: 'Exchange Name',
      ecash_brand: true,
      decimal_place: 0,
      ecash_deposit_address_format: true,
      url: '/',
      trading_open: true,
      trade_fee: 10,
      score: 0,
      logo: { data: [{ attributes: { url: '' } }] },
    },
  });

  useEffect(() => {
    const Filter = (data) => {
      const result = data.filter(
        (item) =>
          item.attributes.name
            .toLowerCase()
            .replace(/\s/g, '-')
            .replace(/\./g, '-') === exchange
      );
      return result[0];
    };
    setExchangeData(Filter(data));
  }, [data, exchangedata, exchange]);

  const CheckTrue = ({ type, item }) => {
    if (type === 'decimal') {
      return <>{item === null ? <Unsure /> : item}</>;
    } else if (type === 'deposit_confirmations') {
      return <>{item === null || item === undefined ? <Unsure /> : item}</>;
    } else if (type === 'issues') {
      return <>{item === null || item === '' ? <Check /> : <X />}</>;
    } else if (type === 'withdrawal_fee') {
      return (
        <>
          {item <= 100 || typeof item === 'string' ? (
            <Check />
          ) : item !== undefined ? (
            <X />
          ) : (
            <Unsure />
          )}
        </>
      );
    } else return <>{item === null ? <Unsure /> : item ? <Check /> : <X />}</>;
  };

  const CheckTrueStyle = ({ type, item }) => {
    if (type === 'decimal') {
      return item === 2 ? 'blue' : item === null ? 'gray' : 'black';
    } else if (type === 'deposit_confirmations') {
      return item === undefined || item === null
        ? 'gray'
        : item <= 1
        ? 'blue'
        : 'red';
    } else if (type === 'withdrawal_fee') {
      return item <= 100
        ? 'blue'
        : item <= 1000
        ? 'black'
        : item === undefined || typeof item === 'string'
        ? 'gray'
        : 'red';
    } else if (type === 'issues') {
      return item === null || item === '' ? 'blue' : 'red';
    } else return item === null ? 'gray' : item ? 'blue' : 'red';
  };

  const Check = () => {
    return <img src={CheckMark} alt="Yes" />;
  };

  const X = () => {
    return <img src={XMark} alt="No" />;
  };

  const Unsure = () => {
    return '?';
  };

  const DetailsRow = ({ type, item, title }) => {
    return (
      <div className={`detail-row ${CheckTrueStyle({ type, item })}`}>
        <div className="detail-row-checkbox">
          <CheckTrue type={type} item={item} />
        </div>
        <h3>{title}</h3>
      </div>
    );
  };

  return (
    <>
      <div className="list-ctn">
        <div className="list-ctn-inner">
          {loading && <div>Loading...</div>}
          {!loading && (
            <>
              <div className="details-ctn">
                <div className="logo-score-ctn">
                  {exchangedata.attributes.url !== undefined ? (
                    <Link
                      to={{
                        pathname: exchangedata.attributes.url,
                      }}
                      target="_blank"
                      rel="noreferrer"
                      className="exchange-detail-img"
                    >
                      <img
                        src={
                          strapiUrl +
                          exchangedata.attributes.logo.data[0].attributes.url
                        }
                        alt={exchangedata.attributes.name}
                      />
                    </Link>
                  ) : (
                    <div className="exchange-detail-img">
                      <img
                        src={
                          strapiUrl +
                          exchangedata.attributes.logo.data[0].attributes.url
                        }
                        alt={exchangedata.attributes.name}
                      />
                    </div>
                  )}
                </div>

                {/********************************** TRADING OPEN *****************************************/}

                <DetailsRow
                  item={exchangedata.attributes.trading_open}
                  title="Trading Open"
                />
                {exchangedata.attributes.trading_open === false && (
                  <div className="error-row">
                    Users need to be able to trade eCash.
                  </div>
                )}
                {exchangedata.attributes.trading_open === undefined && (
                  <div className="error-row-undefined">
                    Users need to be able to trade eCash.
                  </div>
                )}

                {/********************************** ADDRESS *****************************************/}

                <DetailsRow
                  item={exchangedata.attributes.ecash_deposit_address_format}
                  title="eCash address format"
                />
                {exchangedata.attributes.ecash_deposit_address_format ===
                  false && (
                  <div className="error-row">
                    Addresses need to be using the eCash address format
                    (ecash:XXX).
                  </div>
                )}
                {exchangedata.attributes.ecash_deposit_address_format ===
                  null && (
                  <div className="error-row-undefined">
                    Addresses need to be using the eCash address format
                    (ecash:XXX).
                  </div>
                )}

                {/********************************** CONFIRMATIONS *****************************************/}

                <DetailsRow
                  type="deposit_confirmations"
                  item={exchangedata.attributes.deposit_confirmations}
                  title={`Deposit Confirmation${
                    exchangedata.attributes.deposit_confirmations > 1 ||
                    exchangedata.attributes.deposit_confirmations ===
                      undefined ||
                    exchangedata.attributes.deposit_confirmations === null
                      ? 's'
                      : ''
                  }`}
                />
                {exchangedata.attributes.deposit_confirmations > 1 && (
                  <div className="error-row" style={{ background: '#b95e5e' }}>
                    Deposits should be credited once finalized (currently 1
                    block).
                  </div>
                )}
                {(exchangedata.attributes.deposit_confirmations === undefined ||
                  exchangedata.attributes.deposit_confirmations === null) && (
                  <div className="error-row-undefined">
                    Deposits should be credited once finalized (currently 1
                    block).
                  </div>
                )}

                {/********************************** FEE *****************************************/}

                {/* <DetailsRow
                  type="withdrawal_fee"
                  item={exchangedata.attributes.trade_fee}
                  title={`Trade Fee${
                    exchangedata.attributes.trade_fee !== undefined
                      ? ': ' +
                        exchangedata.attributes.trade_fee.toLocaleString() +
                        ' bits (XEC)'
                      : ''
                  }`}
                />
                {exchangedata.attributes.trade_fee > 100 && (
                  <div
                    className="error-row"
                    style={
                      exchangedata.attributes.trade_fee <= 1000
                        ? { background: '#12161b' }
                        : { background: '#b95e5e' }
                    }
                  >
                    A fee of 2 bits (XEC) covers most eCash transactions. A fee
                    at or below 1000 bits is considered reasonable, with
                    anything at or below 100 bits is considered ideal.
                  </div>
                )}
                {exchangedata.attributes.trade_fee === undefined && (
                  <div className="error-row-undefined">
                    A fee of 2 bits (XEC) covers most eCash transactions. A fee
                    at or below 1000 bits is considered reasonable, with
                    anything at or below 100 bits is considered ideal.
                  </div>
                )} */}

                {/********************************** DECIMAL *****************************************/}

                <DetailsRow
                  type="decimal"
                  item={exchangedata.attributes.decimal_place}
                  title="Decimal Place"
                />
                {exchangedata.attributes.decimal_place !== 2 &&
                exchangedata.attributes.decimal_place !== null ? (
                  exchangedata.attributes.decimal_place !== null ? (
                    <div
                      className="error-row"
                      style={{ background: '#12161b' }}
                    >
                      eCash uses bits (2 decimals), which should be used when
                      trading, depositing, and withdrawing.
                    </div>
                  ) : (
                    <div className="error-row-undefined">
                      eCash uses bits (2 decimals), which should be used when
                      trading, depositing, and withdrawing.
                    </div>
                  )
                ) : null}

                {/********************************** quirks_issues *****************************************/}

                <DetailsRow
                  type="issues"
                  item={exchangedata.attributes.issues}
                  title="Free of Other Issues"
                />
                {exchangedata.attributes.issues === null ||
                exchangedata.attributes.issues === '' ? null : (
                  <div className="error-row">
                    {exchangedata.attributes.issues}
                  </div>
                )}
                <div className="score-ctn">
                  <div
                    className="scorebox"
                    style={
                      exchangedata.attributes.score >= 80
                        ? { backgroundColor: '#0074C2' }
                        : exchangedata.attributes.score >= 60
                        ? { backgroundColor: '#005792' }
                        : exchangedata.attributes.score >= 40
                        ? { backgroundColor: '#00426e' }
                        : exchangedata.attributes.score >= 20
                        ? { backgroundColor: '#003253' }
                        : exchangedata.attributes.score >= 0
                        ? { backgroundColor: '#00263e' }
                        : { backgroundColor: '#001d2f' }
                    }
                  >
                    {exchangedata.attributes.score.toFixed()}
                  </div>
                  Total Score
                </div>
              </div>

              {history.action !== 'POP' ? (
                <div className="back-btn" onClick={() => history.goBack()}>
                  Back
                </div>
              ) : (
                <a className="back-btn" href="/">
                  Home
                </a>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ExchangePage;
