import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const DecimalPlace = ({ data, loading }) => {
  const [labels, setLabels] = useState({ yeslabels: [], nolabels: [] });
  const exchangedata2 = {
    labels: labels.labels,
    datasets: [
      {
        data: labels.values,
        backgroundColor: labels.colors,
        decimal_place: labels.decimal_place,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            let index = tooltipItem.dataIndex;
            return (
              tooltipItem.label +
              ' - ' +
              tooltipItem.dataset.decimal_place[index].value.toString()
            );
          },
        },
      },
    },
  };

  useEffect(() => {
    const GetLabels = (data) => {
      const yeslabels = [];
      const nolabels = [];
      const values = [];
      const yescolors = [];
      const nocolors = [];
      const yesdecimal_place = [];
      const nodecimal_place = [];
      const yesName = [];
      const noName = [];
      for (let i = 0; i < data.length; ++i) {
        if (data[i].attributes.decimal_places !== null) {
          if (data[i].attributes.decimal_places === 2) {
            yeslabels.push(data[i].attributes.name);
            values.push(1);
            yescolors.push('#0074c2');
            yesdecimal_place.push({
              value: data[i].attributes.decimal_places,
              index: i,
            });
            yesName.push(data[i].attributes.name);
          } else {
            nolabels.push(data[i].attributes.name);
            values.push(1);
            nocolors.push('#0d1014');
            nodecimal_place.push({
              value: data[i].attributes.decimal_places,
              index: i,
            });
            noName.push(data[i].attributes.name);
          }
        }
      }
      return {
        labels: yeslabels.concat(nolabels),
        values: values,
        colors: yescolors.concat(nocolors),
        yeslabels: yeslabels,
        nolabels: nolabels,
        decimal_place: yesdecimal_place.concat(nodecimal_place),
      };
    };
    setLabels(GetLabels(data));
  }, [data]);

  return (
    <>
      <h2>Decimal Place</h2>
      <div className="doughnut-ctn">
        <div className="doughnut-ctn-inner">
          <Doughnut options={options} data={exchangedata2} />
        </div>
        <div className="yes-row">
          <span>Decimal Place Correct</span>
          <br />
          {labels.yeslabels.map((yes, i) => {
            if (i + 1 === labels.yeslabels.length) {
              return yes;
            } else {
              return yes + ', ';
            }
          })}
        </div>
        <div className="yes-row">
          <span>Decimal Place Not Correct</span>
          <br />
          {labels.nolabels.map((no, i) => {
            if (i + 1 === labels.nolabels.length) {
              return no;
            } else {
              return no + ', ';
            }
          })}
        </div>
      </div>
    </>
  );
};

export default DecimalPlace;
