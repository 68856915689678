import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Blip from './images/blip.png';

const Header = () => {
  const [modal, setModal] = useState(false);
  return (
    <div className="header-ctn">
      <div>
        <Link to="/">
          <h1 className="glitch" data-text="Scorecard">
            Scorecard
          </h1>
        </Link>
        <img src={Blip} alt="ecash" className="blip" />
      </div>
      <div className="nav-ctn">
        <div className="navlink" onClick={() => setModal(true)}>
          About
        </div>
        <Link to="/withdrawals-working" className="navlink">
          Compare
        </Link>
        <Link
          to={{
            pathname: 'https://github.com/ecashxec/ecash-services/issues',
          }}
          target="_blank"
          rel="noreferrer"
          className="navlink"
        >
          Report an Issue
        </Link>
        {modal && (
          <div
            className="modal-backdrop animate__animated animate__fadeIn"
            style={{
              width: '100%',
            }}
            onClick={() => setModal(false)}
          >
            <div
              className="modal-border animate__animated animate__flipInX"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-inner">
                Scorecard.cash highlights prominent eCash-supporting exchanges &
                services and scores them based on how well they adhere to the
                eCash standards. All exchanges & services should be using the
                correct branding, units (bits), and address format. Exchanges
                should also accept deposits within 1 block (about 5 minutes) and
                withdrawal fees should be low.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
