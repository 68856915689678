import React from 'react';
import { Link } from 'react-router-dom';
import './barchart.css';
import SiteLinks from '../SiteLinks/SiteLinks';

const links = [
  { name: 'Withdrawals Working', link: '/withdrawals-working' },
  { name: 'Deposits Working', link: '/deposits-working' },
  { name: 'eCash address format', link: '/ecash-address-format' },
  { name: 'Deposit Confirmations', link: '/deposit-confirmations' },
  { name: 'Withdrawal Fee', link: '/withdrawal-fee' },
  { name: 'Trading Pairs', link: '/trading-pairs' },
  { name: 'Decimal Place', link: '/decimal-place' },
];

const ComparePage = ({ loading, children }) => {
  return (
    <>
      <div className="barchart-ctn">
        <div className="barchart-button-ctn">
          {links.map((page) => {
            return (
              <Link
                to={page.link}
                className="back-btn2"
                style={
                  window.location.pathname === page.link
                    ? { backgroundColor: '#0074c2' }
                    : null
                }
              >
                {page.name}
              </Link>
            );
          })}
        </div>
        <div className="barchart-ctn-inner">{children}</div>
        <div className="barchart-button-ctn">
          <Link className="back-btn3" to="/">
            Home
          </Link>
        </div>
      </div>
      <SiteLinks />
    </>
  );
};

export default ComparePage;
