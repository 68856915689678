import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return tooltipItem.label;
        },
      },
    },
  },
};

const WithdrawalsWorking = ({ data }) => {
  const [labels, setLabels] = useState({ yeslabels: [], nolabels: [] });
  const exchangedata2 = {
    labels: labels.labels,
    datasets: [
      {
        data: labels.values,
        backgroundColor: labels.colors,
      },
    ],
  };

  useEffect(() => {
    const GetLabels = (data) => {
      const yeslabels = [];
      const nolabels = [];
      const values = [];
      const yescolors = [];
      const nocolors = [];
      for (let i = 0; i < data.length; ++i) {
        if (data[i].attributes.withdrawals_working !== null) {
          if (data[i].attributes.withdrawals_working === true) {
            yeslabels.push(data[i].attributes.name);
            values.push(1);
            yescolors.push('#0074c2');
          } else {
            nolabels.push(data[i].attributes.name);
            values.push(1);
            nocolors.push('#af0707');
          }
        }
      }
      return {
        labels: yeslabels.concat(nolabels),
        values: values,
        colors: yescolors.concat(nocolors),
        yeslabels: yeslabels,
        nolabels: nolabels,
      };
    };
    setLabels(GetLabels(data));
  }, [data]);

  return (
    <>
      <h2>Withdrawals Working</h2>
      <div className="doughnut-ctn">
        <div className="doughnut-ctn-inner">
          <Doughnut options={options} data={exchangedata2} />
        </div>

        <div className="yes-row">
          <span>Withdrawals Working</span>
          <br />
          {labels.yeslabels.map((yes, i) => {
            if (i + 1 === labels.yeslabels.length) {
              return yes;
            } else {
              return yes + ', ';
            }
          })}
        </div>
        <div className="yes-row">
          <span>Withdrawals Not Working</span>
          <br />
          {labels.nolabels.map((no, i) => {
            if (i + 1 === labels.nolabels.length) {
              return no;
            } else {
              return no + ', ';
            }
          })}
        </div>
      </div>
    </>
  );
};

export default WithdrawalsWorking;
