import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './Header';
import LandingPage from './LandingPage';
import ExchangePage from './ExchangePage';
import InstantExchangePage from './InstantExchangePage';
import ServicesPage from './ServicesPage';
import ComparePage from './Charts/ComparePage';
import WithdrawalsWorking from './Charts/WithdrawalsWorking';
import DepositsWorking from './Charts/DepositsWorking';
import UsingBrand from './Charts/UsingBrand';
import AddressFormat from './Charts/AddressFormat';
import DepositConfirmations from './Charts/DepositConfirmations';
import WithdrawalFee from './Charts/WithdrawalFee';
import TradingPairs from './Charts/TradingPairs';
import DecimalPlace from './Charts/DecimalPlace';

const strapiUrl = 'https://api.scorecard.cash';
const strapiQuery = '?pagination[pageSize]=100&populate=*';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [exchangedata, setExchangeData] = useState([
    {
      attributes: {
        name: 'Exchange Name',
        ecash_brand: true,
        decimal_places: 0,
        score: 0,
        ecash_deposit_address_format: true,
        ecash_website_link: true,
        url: '/',
        deposits_working: true,
        withdrawals_working: true,
        withdrawal_fee: 10,
        trading_pairs: ['USDT'],
        logo: { data: { attributes: { url: '' } } },
      },
    },
  ]);
  const [instantexchangedata, setInstantExchangeData] = useState([
    {
      attributes: {
        name: 'Exchange Name',
        ecash_brand: true,
        decimal_place: 0,
        ecash_deposit_address_format: true,
        url: '/',
        trading_open: true,
        score: 0,
        trade_fee: 10,
        logo: { data: [{ attributes: { url: '' } }] },
      },
    },
  ]);
  const [servicedata, setServiceData] = useState([
    {
      attributes: {
        name: 'Service Name',
        ecash_brand: true,
        decimal_place: 0,
        score: 0,
        ecash_address_format: true,
        url: '/',
        deposits_working: true,
        withdrawals_working: true,
        issues: null,
        logo: { data: [{ attributes: { url: '' } }] },
      },
    },
  ]);
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    fetch(`${strapiUrl}/api/exchanges${strapiQuery}`, {})
      .then((res) => res.json())
      .then((response) => {
        const exchangeData = response.data;
        // const instantexchangeData = response[1].instant_exchanges;
        // const serviceData = response[2].services;
        for (let i = 0; i < exchangeData.length; ++i) {
          const element = exchangeData[i].attributes;
          let score = 0;
          const withdrawal = element.withdrawals_working;
          const deposit = element.deposits_working;
          const address = element.ecash_deposit_address_format;
          const depositConfirmations = element.deposit_confirmations;
          const withdrawalFee = element.withdrawal_fee;
          const pairs = element.trading_pairs;
          const decimals = element.decimal_places;

          if (withdrawal) {
            score += 20;
          }
          if (deposit) {
            score += 20;
          }
          if (address && address !== null) {
            score += 20;
          }
          if (depositConfirmations <= 1 && depositConfirmations !== null) {
            score += 15;
          }
          if (withdrawalFee <= 100 && withdrawalFee !== null) {
            score += 10;
          }
          if (
            withdrawalFee <= 1000 &&
            withdrawalFee > 100 &&
            withdrawalFee !== null
          ) {
            score += 5;
          }
          if (pairs.length >= 2) {
            score += 10;
          }
          if (decimals === 2) {
            score += 5;
          }
          element.score = (score / 100) * 100;
        }
        exchangeData.sort((a, b) =>
          a.attributes.name.localeCompare(b.attributes.name)
        );
        exchangeData.sort(function (a, b) {
          return (
            a.attributes.deposit_confirmations -
            b.attributes.deposit_confirmations
          );
        });
        exchangeData.sort(function (a, b) {
          return b.attributes.score - a.attributes.score;
        });
        setExchangeData(exchangeData);
        setLoading(false);
      })
      .catch((error) => console.log(error));

    /////////////////////////////////////////////// INSTANT EXCHANGES

    fetch(`${strapiUrl}/api/instant-exchanges${strapiQuery}`, {})
      .then((res) => res.json())
      .then((response) => {
        const instantexchangeData = response.data;
        for (let i = 0; i < instantexchangeData.length; ++i) {
          const element = instantexchangeData[i].attributes;
          let score = 0;
          const trading_open = element.trading_open;
          const address = element.ecash_deposit_address_format;
          const depositConfirmations = element.deposit_confirmations;
          const decimals = element.decimal_place;
          const issues = element.issues;

          if (trading_open && trading_open !== null) {
            score += 30;
          }
          if (address && address !== null) {
            score += 25;
          }
          if (depositConfirmations <= 1 && depositConfirmations !== null) {
            score += 25;
          }
          if (decimals === 2) {
            score += 10;
          }
          if (issues === null) {
            score += 10;
          }
          element.score = (score / 100) * 100;
        }
        instantexchangeData.sort((a, b) =>
          a.attributes.name.localeCompare(b.attributes.name)
        );
        instantexchangeData.sort(function (a, b) {
          return (
            a.attributes.deposit_confirmations -
            b.attributes.deposit_confirmations
          );
        });
        instantexchangeData.sort(function (a, b) {
          return b.attributes.score - a.attributes.score;
        });
        setInstantExchangeData(instantexchangeData);
        setLoading2(false);
      })
      .catch((error) => console.log(error));

    /////////////////////////////////////////////// APPS / SERVICES

    fetch(`${strapiUrl}/api/apps-services${strapiQuery}`, {})
      .then((res) => res.json())
      .then((response) => {
        const serviceData = response.data;

        for (let i = 0; i < serviceData.length; ++i) {
          const element = serviceData[i].attributes;
          let score = 0;
          const address = element.ecash_address_format;
          const decimals = element.decimal_place;
          const issues = element.issues;
          if (address === true) {
            score += 40;
          }
          if (decimals === 2) {
            score += 30;
          }
          if (issues === 'none' || issues === '' || issues === null) {
            score += 30;
          }
          element.score = (score / 100) * 100;
        }
        serviceData.sort((a, b) =>
          a.attributes.name.localeCompare(b.attributes.name)
        );
        serviceData.sort(function (a, b) {
          return b.attributes.score - a.attributes.score;
        });
        setServiceData(serviceData);
        setLoading3(false);
      })
      .catch((error) => console.log(error));

    setTimeout(() => {
      setAnimate(false);
    }, 4000);
  }, []);

  return (
    <>
      <Router>
        <Header />
        <Route
          exact
          path="/"
          render={(props) => (
            <LandingPage
              {...props}
              exchangedata={exchangedata}
              instantexchangedata={instantexchangedata}
              servicedata={servicedata}
              loading={loading}
              loading2={loading2}
              loading3={loading3}
              animate={animate}
              strapiUrl={strapiUrl}
            />
          )}
        />
        <Switch>
          <Route
            path="/exchange/:exchange"
            render={(props) => (
              <ExchangePage
                {...props}
                data={exchangedata}
                loading={loading}
                strapiUrl={strapiUrl}
              />
            )}
          />
          <Route
            path="/instant-exchange/:exchange"
            render={(props) => (
              <InstantExchangePage
                {...props}
                data={instantexchangedata}
                loading={loading2}
                strapiUrl={strapiUrl}
              />
            )}
          />
          <Route
            path="/service/:service"
            render={(props) => (
              <ServicesPage
                {...props}
                data={servicedata}
                loading={loading3}
                strapiUrl={strapiUrl}
              />
            )}
          />
          <Route
            path="/withdrawals-working"
            render={() => (
              <ComparePage loading={loading}>
                <WithdrawalsWorking data={exchangedata} />
              </ComparePage>
            )}
          />
          <Route
            path="/deposits-working"
            render={() => (
              <ComparePage loading={loading}>
                <DepositsWorking data={exchangedata} />
              </ComparePage>
            )}
          />
          <Route
            path="/using-ecash-brand"
            render={() => (
              <ComparePage loading={loading}>
                <UsingBrand data={exchangedata} />
              </ComparePage>
            )}
          />
          <Route
            path="/ecash-address-format"
            render={() => (
              <ComparePage loading={loading}>
                <AddressFormat data={exchangedata} />
              </ComparePage>
            )}
          />
          <Route
            path="/deposit-confirmations"
            render={() => (
              <ComparePage loading={loading}>
                <DepositConfirmations data={exchangedata} />
              </ComparePage>
            )}
          />
          <Route
            path="/withdrawal-fee"
            render={() => (
              <ComparePage loading={loading}>
                <WithdrawalFee data={exchangedata} />
              </ComparePage>
            )}
          />
          <Route
            path="/trading-pairs"
            render={() => (
              <ComparePage loading={loading}>
                <TradingPairs data={exchangedata} />
              </ComparePage>
            )}
          />
          <Route
            path="/decimal-place"
            render={() => (
              <ComparePage loading={loading}>
                <DecimalPlace data={exchangedata} />
              </ComparePage>
            )}
          />
        </Switch>
      </Router>
    </>
  );
};

export default App;
