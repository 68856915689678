import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Check from './images/check.png';
import X from './images/x.png';
import 'animate.css';

const InstantExchanges = ({ data, loading, animate, strapiUrl }) => {
  const checkmark = <img src={Check} alt="yes" />;
  const x = <img src={X} alt="no" />;
  const [hover, setHover] = useState();

  const CheckTrue = (item) => {
    if (item === 'N/A') {
      return 'N/A';
    } else
      return <>{item && item !== null ? checkmark : item !== null ? x : '?'}</>;
  };

  return (
    <>
      {loading && (
        <div className="loading-ctn">
          <div className="loading-ctn-inner">Loading...</div>
        </div>
      )}
      {!loading && (
        <div className="list-ctn padding-right">
          <div className="list-ctn-inner">
            <div
              className={`list-header-ctn ${
                animate ? 'animate__animated animate__fadeIn' : ''
              }`}
              style={{ animationDelay: '0.5s' }}
            >
              <h2>Instant Exchanges</h2>
              <div className="list-header-ctn-inner-INSTANT">
                <div className="list-label-ctn">
                  <div
                    className="list-label-background"
                    style={{ backgroundColor: hover === 1 ? '#273452' : '' }}
                  />
                  <div className="list-label">Trading Open</div>
                </div>
                {/* <div className="list-label-ctn">
                  <div
                    className="list-label-background"
                    style={{ backgroundColor: hover === 2 ? '#273452' : '' }}
                  />
                  <div className="list-label">Using eCash Brand</div>
                </div> */}
                <div className="list-label-ctn">
                  <div
                    className="list-label-background"
                    style={{ backgroundColor: hover === 3 ? '#273452' : '' }}
                  />
                  <div className="list-label">eCash address format</div>
                </div>
                <div className="list-label-ctn">
                  <div
                    className="list-label-background"
                    style={{ backgroundColor: hover === 4 ? '#273452' : '' }}
                  />
                  <div className="list-label">Deposit Confirmations</div>
                </div>
                {/* <div className="list-label-ctn">
                  <div
                    className="list-label-background"
                    style={{ backgroundColor: hover === 5 ? '#273452' : '' }}
                  />
                  <div className="list-label">Trade fee</div>
                </div> */}
                <div className="list-label-ctn">
                  <div
                    className="list-label-background"
                    style={{ backgroundColor: hover === 6 ? '#273452' : '' }}
                  />
                  <div className="list-label">Decimal Place</div>
                </div>
                <div className="list-label-ctn">
                  <div
                    className="list-label-background"
                    style={{ backgroundColor: hover === 7 ? '#273452' : '' }}
                  />
                  <div className="list-label">Free of Other Issues</div>
                </div>
              </div>
            </div>
            {data.map((exchange, index) => {
              return (
                <div
                  key={index}
                  className={`row-ctn ${
                    animate ? 'animate__animated animate__fadeInUp' : ''
                  }`}
                  style={{ animationDelay: index * 0.05 + 's' }}
                >
                  <div
                    className="rank-col"
                    style={
                      exchange.attributes.score >= 80
                        ? { backgroundColor: '#0074C2' }
                        : exchange.attributes.score >= 60
                        ? { backgroundColor: '#005792' }
                        : exchange.attributes.score >= 40
                        ? { backgroundColor: '#00426e' }
                        : exchange.attributes.score >= 20
                        ? { backgroundColor: '#003253' }
                        : exchange.attributes.score >= 0
                        ? { backgroundColor: '#00263e' }
                        : { backgroundColor: '#001d2f' }
                    }
                  >
                    {exchange.attributes.score.toFixed()}
                  </div>
                  <Link
                    to={`/instant-exchange/${exchange.attributes.name
                      .toLowerCase()
                      .replace(/\s/g, '-')
                      .replace(/\./g, '-')}`}
                    className="logo-col"
                  >
                    <img
                      src={
                        strapiUrl +
                        exchange.attributes.logo.data[0].attributes.url
                      }
                      alt={exchange.attributes.name}
                    />
                  </Link>

                  {/************************** TRADING OPEN *****************************/}

                  <div
                    onMouseEnter={() => setHover(1)}
                    onMouseLeave={() => setHover(0)}
                    className="check-col"
                    style={{
                      backgroundColor:
                        exchange.attributes.trading_open &&
                        exchange.attributes.trading_open !== null
                          ? '#0074c2'
                          : exchange.attributes.trading_open === null
                          ? null
                          : '#af0707',
                    }}
                  >
                    {CheckTrue(exchange.attributes.trading_open)}
                  </div>

                  {/************************** Using eCash Brand *****************************/}

                  {/* <div
                    onMouseEnter={() => setHover(2)}
                    onMouseLeave={() => setHover(0)}
                    className="check-col"
                    style={{
                      backgroundColor:
                        exchange.attributes.ecash_brand && exchange.attributes.ecash_brand !== null
                          ? '#0074c2'
                          : exchange.attributes.ecash_brand === null
                          ? null
                          : '#af0707',
                    }}
                  >
                    {CheckTrue(exchange.attributes.ecash_brand)}
                  </div> */}

                  {/************************** ADDRESS FORMAT *****************************/}

                  <div
                    onMouseEnter={() => setHover(3)}
                    onMouseLeave={() => setHover(0)}
                    className="check-col"
                    style={{
                      backgroundColor:
                        exchange.attributes.ecash_deposit_address_format &&
                        exchange.attributes.ecash_deposit_address_format !==
                          null
                          ? '#0074c2'
                          : exchange.attributes.ecash_deposit_address_format ===
                              null ||
                            exchange.attributes.ecash_deposit_address_format ===
                              'N/A'
                          ? null
                          : '#af0707',
                    }}
                  >
                    {exchange.attributes.ecash_deposit_address_format === null
                      ? '?'
                      : exchange.attributes.ecash_deposit_address_format
                      ? checkmark
                      : x}
                  </div>

                  {/************************** Deposit Confirmations *****************************/}

                  <div
                    onMouseEnter={() => setHover(4)}
                    onMouseLeave={() => setHover(0)}
                    className="check-col decimal-col"
                    style={{
                      backgroundColor:
                        exchange.attributes.deposit_confirmations <= 1 &&
                        exchange.attributes.deposit_confirmations !== null
                          ? '#0074c2'
                          : exchange.attributes.deposit_confirmations === null
                          ? null
                          : '#af0707',
                    }}
                  >
                    {exchange.attributes.deposit_confirmations}
                    <span>
                      {exchange.attributes.deposit_confirmations === null &&
                        '?'}
                    </span>
                  </div>

                  {/************************** trade fee *****************************/}

                  {/* <div
                    onMouseEnter={() => setHover(5)}
                    onMouseLeave={() => setHover(0)}
                    className="check-col fee-questionmark"
                    style={{
                      backgroundColor:
                        exchange.attributes.trade_fee <= 100
                          ? '#0074c2'
                          : exchange.attributes.trade_fee <= 1000
                          ? '#0d1014'
                          : exchange.attributes.trade_fee === undefined ||
                            typeof exchange.attributes.trade_fee === 'string'
                          ? null
                          : '#af0707',
                    }}
                  >
                    {exchange.attributes.trade_fee === undefined ? (
                      '?'
                    ) : (
                      <span className="fee-col">
                        {exchange.attributes.trade_fee.toLocaleString()}
                      </span>
                    )}
                  </div> */}

                  {/************************** decimal_places *****************************/}

                  <div
                    onMouseEnter={() => setHover(6)}
                    onMouseLeave={() => setHover(0)}
                    className="check-col decimal-col"
                    style={{
                      backgroundColor:
                        exchange.attributes.decimal_place === 2
                          ? '#0074c2'
                          : exchange.attributes.decimal_place !== null
                          ? '#0d1014'
                          : null,
                    }}
                  >
                    {exchange.attributes.decimal_place}
                    <span>
                      {exchange.attributes.decimal_place === null && '?'}
                    </span>
                  </div>

                  {/************************** issues *****************************/}

                  <div
                    onMouseEnter={() => setHover(7)}
                    onMouseLeave={() => setHover(0)}
                    className="check-col"
                    style={{
                      backgroundColor:
                        exchange.attributes.issues === null ||
                        exchange.attributes.issues === ''
                          ? '#0074c2'
                          : '#af0707',
                    }}
                  >
                    {exchange.attributes.issues === null ||
                    exchange.attributes.issues === ''
                      ? checkmark
                      : x}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default InstantExchanges;
