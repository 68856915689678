import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Check from './images/check.png';
import X from './images/x.png';
import AppsServicesTable from './AppsServicesTable';
import InstantExchanges from './InstantExchanges';
import SiteLinks from './SiteLinks/SiteLinks';
import 'animate.css';

const LandingPage = ({
  exchangedata,
  instantexchangedata,
  servicedata,
  loading,
  loading2,
  loading3,
  animate,
  strapiUrl,
}) => {
  const checkmark = <img src={Check} alt="yes" />;
  const x = <img src={X} alt="no" />;
  const [hover, setHover] = useState();

  const StyleColHeader = (col) => {
    setHover(col);
  };

  const CheckTrue = (item) => {
    if (item === 'N/A') {
      return 'N/A';
    } else return <>{item ? checkmark : item !== null ? x : '?'}</>;
  };

  const goldenScore = (score) => {
    if (score === 100) {
      return `row-ctn perfect-score ${
        animate ? 'animate__animated animate__fadeInUp' : ''
      }`;
    } else
      return `row-ctn ${animate ? 'animate__animated animate__fadeInUp' : ''}`;
  };

  return (
    <>
      {loading && (
        <div className="loading-ctn">
          <div className="loading-ctn-inner">Loading...</div>
        </div>
      )}
      {!loading && (
        <>
          <div className="list-ctn padding-right">
            <div className="list-ctn-inner">
              <div
                className={`list-header-ctn ${
                  animate ? 'animate__animated animate__fadeIn' : ''
                }`}
                style={{ animationDelay: '0.5s' }}
              >
                <h2>Exchanges</h2>
                <div className="list-header-ctn-inner">
                  <Link to="/withdrawals-working" className="list-label-ctn">
                    <div
                      className="list-label-background"
                      style={{ backgroundColor: hover === 1 ? '#273452' : '' }}
                    />
                    <div className="list-label">Withdrawals Working</div>
                  </Link>
                  <Link to="/deposits-working" className="list-label-ctn">
                    <div
                      className="list-label-background"
                      style={{ backgroundColor: hover === 2 ? '#273452' : '' }}
                    />
                    <div className="list-label">Deposits Working</div>
                  </Link>
                  {/* <Link to="/using-ecash-brand" className="list-label-ctn">
                    <div
                      className="list-label-background"
                      style={{ backgroundColor: hover === 3 ? '#273452' : '' }}
                    />
                    <div className="list-label">Using eCash Brand</div>
                  </Link> */}
                  <Link to="/ecash-address-format" className="list-label-ctn">
                    <div
                      className="list-label-background"
                      style={{ backgroundColor: hover === 4 ? '#273452' : '' }}
                    />
                    <div className="list-label">eCash address format</div>
                  </Link>
                  <Link to="/deposit-confirmations" className="list-label-ctn">
                    <div
                      className="list-label-background"
                      style={{ backgroundColor: hover === 5 ? '#273452' : '' }}
                    />
                    <div className="list-label">Deposit Confirmations</div>
                  </Link>

                  <Link to="/withdrawal-fee" className="list-label-ctn">
                    <div
                      className="list-label-background"
                      style={{ backgroundColor: hover === 6 ? '#273452' : '' }}
                    />
                    <div className="list-label">Withdrawal Fee (bits)</div>
                  </Link>
                  <Link to="/trading-pairs" className="list-label-ctn">
                    <div
                      className="list-label-background"
                      style={{ backgroundColor: hover === 8 ? '#273452' : '' }}
                    />
                    <div className="list-label">Trading Pairs</div>
                  </Link>
                  <Link to="/decimal-place" className="list-label-ctn">
                    <div
                      className="list-label-background"
                      style={{ backgroundColor: hover === 7 ? '#273452' : '' }}
                    />
                    <div className="list-label">Decimal Place</div>
                  </Link>
                </div>
              </div>
              {exchangedata.map((exchange, index) => {
                return (
                  <div
                    key={index}
                    className={goldenScore(exchange.attributes.score)}
                    style={{ animationDelay: index * 0.05 + 's' }}
                  >
                    <div
                      className="rank-col"
                      style={
                        exchange.attributes.score >= 80
                          ? { backgroundColor: '#0074C2' }
                          : exchange.attributes.score >= 60
                          ? { backgroundColor: '#005792' }
                          : exchange.attributes.score >= 40
                          ? { backgroundColor: '#00426e' }
                          : exchange.attributes.score >= 20
                          ? { backgroundColor: '#003253' }
                          : exchange.attributes.score >= 0
                          ? { backgroundColor: '#00263e' }
                          : { backgroundColor: '#001d2f' }
                      }
                    >
                      {exchange.attributes.score.toFixed()}
                    </div>
                    <Link
                      to={`/exchange/${exchange.attributes.name
                        .toLowerCase()
                        .replace(/\s/g, '-')
                        .replace(/\./g, '-')}`}
                      className="logo-col"
                    >
                      <img
                        src={
                          strapiUrl +
                          exchange.attributes.logo.data.attributes.url
                        }
                        alt={exchange.attributes.name}
                      />
                      {exchange.attributes.score === 100 && (
                        <div
                          className="perfectscore-text animate__animated animate__fadeInUp"
                          style={{ animationDelay: '0.5s' }}
                        >
                          Perfect Score!
                        </div>
                      )}
                    </Link>

                    {/************************** WITHDRAWALS WORKING *****************************/}

                    <div
                      onMouseEnter={() => StyleColHeader(1)}
                      onMouseLeave={() => StyleColHeader(0)}
                      className="check-col"
                      style={{
                        backgroundColor: exchange.attributes.withdrawals_working
                          ? '#0074c2'
                          : exchange.attributes.withdrawals_working === null
                          ? null
                          : '#af0707',
                      }}
                    >
                      {CheckTrue(exchange.attributes.withdrawals_working)}
                    </div>

                    {/************************** DEPOSITS WORKING *****************************/}

                    <div
                      onMouseEnter={() => StyleColHeader(2)}
                      onMouseLeave={() => StyleColHeader(0)}
                      className="check-col"
                      style={{
                        backgroundColor: exchange.attributes.deposits_working
                          ? '#0074c2'
                          : exchange.attributes.deposits_working === null
                          ? null
                          : '#af0707',
                      }}
                    >
                      {CheckTrue(exchange.attributes.deposits_working)}
                    </div>

                    {/************************** Using eCash Brand *****************************/}

                    {/* <div
                      onMouseEnter={() => StyleColHeader(3)}
                      onMouseLeave={() => StyleColHeader(0)}
                      className="check-col"
                      style={{
                        backgroundColor: exchange.attributes.ecash_brand
                          ? '#0074c2'
                          : exchange.attributes.ecash_brand === null
                          ? null
                          : '#af0707',
                      }}
                    >
                      {CheckTrue(exchange.attributes.ecash_brand)}
                    </div> */}

                    {/************************** ADDRESS FORMAT *****************************/}

                    <div
                      onMouseEnter={() => StyleColHeader(4)}
                      onMouseLeave={() => StyleColHeader(0)}
                      className="check-col"
                      style={{
                        backgroundColor:
                          exchange.attributes.ecash_deposit_address_format ===
                          null
                            ? null
                            : exchange.attributes.ecash_deposit_address_format
                            ? '#0074c2'
                            : '#af0707',
                      }}
                    >
                      {exchange.attributes.ecash_deposit_address_format
                        ? checkmark
                        : exchange.attributes.ecash_deposit_address_format !==
                          null
                        ? x
                        : '?'}
                    </div>

                    {/************************** Deposit Confirmations *****************************/}

                    <div
                      onMouseEnter={() => StyleColHeader(5)}
                      onMouseLeave={() => StyleColHeader(0)}
                      className="check-col decimal-col"
                      style={{
                        backgroundColor:
                          exchange.attributes.deposit_confirmations === null
                            ? null
                            : exchange.attributes.deposit_confirmations <= 1
                            ? '#0074c2'
                            : '#af0707',
                      }}
                    >
                      {exchange.attributes.deposit_confirmations}
                      <span>
                        {exchange.attributes.deposit_confirmations === null &&
                          '?'}
                      </span>
                    </div>

                    {/************************** withdrawal_fee_reasonable *****************************/}

                    <div
                      onMouseEnter={() => StyleColHeader(6)}
                      onMouseLeave={() => StyleColHeader(0)}
                      className="check-col fee-questionmark"
                      style={{
                        backgroundColor:
                          exchange.attributes.withdrawal_fee === null
                            ? null
                            : exchange.attributes.withdrawal_fee <= 100
                            ? '#0074c2'
                            : exchange.attributes.withdrawal_fee <= 1000
                            ? '#0d1014'
                            : '#af0707',
                      }}
                    >
                      {exchange.attributes.withdrawal_fee === null ? (
                        '?'
                      ) : (
                        <span className="fee-col">
                          {exchange.attributes.withdrawal_fee.toLocaleString()}
                        </span>
                      )}
                    </div>

                    {/************************** trading_pairs *****************************/}

                    <div
                      onMouseEnter={() => StyleColHeader(8)}
                      onMouseLeave={() => StyleColHeader(0)}
                      className="check-col decimal-col"
                      style={
                        exchange.attributes.trading_pairs !== null
                          ? {
                              backgroundColor:
                                exchange.attributes.trading_pairs.length >= 2
                                  ? '#0074c2'
                                  : exchange.attributes.trading_pairs.length <=
                                    1
                                  ? '#0d1014'
                                  : null,
                            }
                          : null
                      }
                    >
                      {exchange.attributes.trading_pairs.length !== null ? (
                        exchange.attributes.trading_pairs.length
                      ) : (
                        <span>?</span>
                      )}
                    </div>

                    {/************************** decimal_places *****************************/}

                    <div
                      onMouseEnter={() => StyleColHeader(7)}
                      onMouseLeave={() => StyleColHeader(0)}
                      className="check-col decimal-col"
                      style={{
                        backgroundColor:
                          exchange.attributes.decimal_places === 2
                            ? '#0074c2'
                            : exchange.attributes.decimal_places !== null
                            ? '#0d1014'
                            : null,
                      }}
                    >
                      {exchange.attributes.decimal_places}
                      <span>
                        {exchange.attributes.decimal_places === null && '?'}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <InstantExchanges
            data={instantexchangedata}
            loading={loading2}
            animate={animate}
            strapiUrl={strapiUrl}
          />
          <AppsServicesTable
            data={servicedata}
            loading={loading3}
            animate={animate}
            strapiUrl={strapiUrl}
          />
          <div className="list-ctn" style={{ paddingTop: '0px' }}>
            <div className="list-ctn-inner" style={{ padding: '0px' }}>
              <div className="bottom-text-ctn">
                <h2>Found a mistake?</h2>
                <p>
                  We rely on community input to maintain these rankings. If you
                  find something on an exchange or service that is not
                  consistent with this information, please let us know by
                  opening an issue on the public{' '}
                  <a
                    href="https://github.com/ecashxec/ecash-services"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GitHub project
                  </a>
                  .
                </p>
                <Link
                  to={{
                    pathname:
                      'https://github.com/ecashxec/ecash-services/issues',
                  }}
                  target="_blank"
                  rel="noreferrer"
                  className="glow-on-hover"
                >
                  Report an Issue
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
      {!loading && <SiteLinks />}
    </>
  );
};

export default LandingPage;
