import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CheckMark from './images/check.png';
import XMark from './images/x.png';

const ServicesPage = ({ match, data, loading, strapiUrl }) => {
  const history = useHistory();
  const {
    params: { service },
  } = match;

  const [exchangedata, setExchangeData] = useState({
    attributes: {
      name: 'Exchange Name',
      ecash_brand: true,
      decimal_place: '0.00',
      decimal_move: true,
      ecash_deposit_address_format: true,
      ecash_website_link: true,
      url: '/',
      deposits_working: true,
      withdrawals_working: true,
      withdrawal_fee: 10,
      score: 0,
      logo: { data: [{ attributes: { url: '' } }] },
    },
  });

  useEffect(() => {
    const Filter = (data) => {
      const result = data.filter(
        (item) =>
          item.attributes.name
            .toLowerCase()
            .replace(/\s/g, '-')
            .replace(/\./g, '-') === service
      );
      return result[0];
    };
    setExchangeData(Filter(data));
  }, [data, exchangedata, service]);

  const CheckTrue = ({ type, item }) => {
    if (type === 'decimal') {
      return <>{item === 2 ? item : item !== null ? item : <Unsure />}</>;
    } else if (type === 'deposit_confirmations') {
      return (
        <>
          {item <= 10 && item !== null ? (
            item
          ) : item !== null ? (
            item
          ) : (
            <Unsure />
          )}
        </>
      );
    } else if (type === 'withdrawal_fee') {
      return <>{item !== false ? <Check /> : <X />}</>;
    } else if (type === 'issues') {
      return <>{item === null || item === '' ? <Check /> : <X />}</>;
    } else
      return (
        <>{item === true ? <Check /> : item !== null ? <X /> : <Unsure />}</>
      );
  };

  const CheckTrueStyle = ({ type, item }) => {
    if (type === 'decimal') {
      return item === 2 ? 'blue' : item !== null ? 'black' : 'gray';
    } else if (type === 'deposit_confirmations') {
      return item <= 10 && item !== null
        ? 'blue'
        : item !== null
        ? 'red'
        : 'gray';
    } else if (type === 'withdrawal_fee') {
      return item !== false ? 'blue' : 'red';
    } else if (type === 'issues') {
      return item === null || item === '' ? 'blue' : 'red';
    } else return item === true ? 'blue' : item !== null ? 'red' : 'gray';
  };

  const Check = () => {
    return <img src={CheckMark} alt="Yes" />;
  };

  const X = () => {
    return <img src={XMark} alt="No" />;
  };

  const Unsure = () => {
    return '?';
  };

  const DetailsRow = ({ type, item, title }) => {
    return (
      <div className={`detail-row ${CheckTrueStyle({ type, item })}`}>
        <div className="detail-row-checkbox">
          <CheckTrue type={type} item={item} />
        </div>
        <h3>{title}</h3>
      </div>
    );
  };

  return (
    <>
      <div className="list-ctn">
        <div className="list-ctn-inner">
          {loading && <div>Loading...</div>}
          {!loading && (
            <>
              <div className="details-ctn">
                <div className="logo-score-ctn">
                  {exchangedata.attributes.url !== null ? (
                    <Link
                      to={{
                        pathname: exchangedata.attributes.url,
                      }}
                      target="_blank"
                      rel="noreferrer"
                      className="exchange-detail-img"
                    >
                      <img
                        src={
                          strapiUrl +
                          exchangedata.attributes.logo.data[0].attributes.url
                        }
                        alt={exchangedata.attributes.name}
                      />
                    </Link>
                  ) : (
                    <div className="exchange-detail-img">
                      <img
                        src={
                          strapiUrl +
                          exchangedata.attributes.logo.data[0].attributes.url
                        }
                        alt={exchangedata.attributes.name}
                      />
                    </div>
                  )}
                </div>

                {/********************************** ADDRESS *****************************************/}

                <DetailsRow
                  item={exchangedata.attributes.ecash_address_format}
                  title="eCash address format"
                />
                {exchangedata.attributes.ecash_address_format === false && (
                  <div className="error-row">
                    Addresses need to be using the eCash address format
                    (ecash:XXX).
                  </div>
                )}

                {/********************************** DECIMAL *****************************************/}

                <DetailsRow
                  type="decimal"
                  item={exchangedata.attributes.decimal_place}
                  title="Decimal Place"
                />
                {exchangedata.attributes.decimal_place !== 2 ? (
                  exchangedata.attributes.decimal_place !== null ? (
                    <div
                      className="error-row"
                      style={{ background: '#12161b' }}
                    >
                      eCash uses bits (2 decimals), which should be used when
                      trading, depositing, and withdrawing.
                    </div>
                  ) : (
                    <div className="error-row-undefined">
                      eCash uses bits (2 decimals), which should be used when
                      trading, depositing, and withdrawing.
                    </div>
                  )
                ) : null}

                {/********************************** quirks_issues *****************************************/}

                <DetailsRow
                  type="issues"
                  item={exchangedata.attributes.issues}
                  title="Free of Other Issues"
                />
                {exchangedata.attributes.issues === null ||
                exchangedata.attributes.issues === '' ? null : (
                  <div className="error-row">
                    {exchangedata.attributes.issues}
                  </div>
                )}
                {exchangedata.attributes.issues === undefined && (
                  <div className="error-row-undefined">No issues known</div>
                )}

                <div className="score-ctn">
                  <div
                    className="scorebox"
                    style={
                      exchangedata.attributes.score >= 80
                        ? { backgroundColor: '#0074C2' }
                        : exchangedata.attributes.score >= 60
                        ? { backgroundColor: '#005792' }
                        : exchangedata.attributes.score >= 40
                        ? { backgroundColor: '#00426e' }
                        : exchangedata.attributes.score >= 20
                        ? { backgroundColor: '#003253' }
                        : exchangedata.attributes.score >= 0
                        ? { backgroundColor: '#00263e' }
                        : { backgroundColor: '#001d2f' }
                    }
                  >
                    {exchangedata.attributes.score.toFixed()}
                  </div>
                  Total Score
                </div>
              </div>

              {history.action !== 'POP' ? (
                <div className="back-btn" onClick={() => history.goBack()}>
                  Back
                </div>
              ) : (
                <a className="back-btn" href="/">
                  Home
                </a>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ServicesPage;
