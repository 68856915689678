import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        color: 'rgba(255,255,255,0.1)',
      },
      ticks: {
        color: '#fff',
      },
    },
    y: {
      grid: {
        drawBorder: false,
        color: 'rgba(255,255,255,0.1)',
      },
      ticks: {
        color: '#fff',
      },
      // type: 'logarithmic',
    },
  },
};

const TradingPairs = ({ data, loading }) => {
  const [labels, setLabels] = useState({});
  const exchangedata2 = {
    labels: labels.labels,
    datasets: [
      {
        label: 'Trading Pairs',
        data: labels.values,
        backgroundColor: labels.colors,
        borderColor: ['rgba(255,255,255,0.2)'],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const GetLabels = (data) => {
      const labels = [];
      const values = [];
      const colors = [];
      let sorteddata = data.map((x) => x);
      sorteddata.sort((a, b) =>
        a.attributes.name.localeCompare(b.attributes.name)
      );
      sorteddata.sort(function (a, b) {
        return (
          b.attributes.trading_pairs.length - a.attributes.trading_pairs.length
        );
      });
      for (let i = 0; i < sorteddata.length; ++i) {
        if (sorteddata[i].trading_pairs !== null) {
          labels.push(sorteddata[i].attributes.name);
          values.push(sorteddata[i].attributes.trading_pairs.length);
          if (sorteddata[i].attributes.trading_pairs.length >= 2) {
            colors.push('#0074c2');
          } else {
            colors.push('#0d1014');
          }
        }
      }
      return { labels: labels, values: values, colors: colors };
    };
    setLabels(GetLabels(data));
  }, [data]);

  return (
    <>
      <h2>Trading Pairs</h2>
      <Bar options={options} data={exchangedata2} />
    </>
  );
};

export default TradingPairs;
