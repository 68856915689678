import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  // LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  // LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        label: function (tooltipItems) {
          let x = tooltipItems.raw;
          if (x === 0.5) {
            return '0 Bits!';
          } else return x.toLocaleString() + ' Bits';
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        color: 'rgba(255,255,255,0.1)',
      },
      ticks: {
        color: '#fff',
        // minRotation: 90,
      },
    },
    y: {
      // type: 'logarithmic',
      grid: {
        drawBorder: false,
        color: 'rgba(255,255,255,0.1)',
      },
      ticks: {
        color: '#fff',
      },
    },
  },
};

const WithdrawalFee = ({ data, loading }) => {
  const [labels, setLabels] = useState({});
  const [highlights, setHighlights] = useState({});
  const exchangedata2 = {
    labels: labels.labels,
    datasets: [
      {
        label: 'Withdrawal Fee',
        data: labels.values,
        backgroundColor: labels.colors,
        borderColor: ['rgba(255,255,255,0.2)'],
        borderWidth: 1,
      },
    ],
  };
  const exchangedata1 = {
    labels: highlights.labels,
    datasets: [
      {
        label: 'Withdrawal Fee',
        data: highlights.values,
        backgroundColor: highlights.colors,
        borderColor: ['rgba(255,255,255,0.2)'],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const GetLabels = (data) => {
      const labels = [];
      const values = [];
      const colors = [];
      let sorteddata = data.map((x) => x);
      sorteddata.sort((a, b) =>
        a.attributes.name.localeCompare(b.attributes.name)
      );
      sorteddata.sort(function (a, b) {
        return a.attributes.withdrawal_fee - b.attributes.withdrawal_fee;
      });
      for (let i = 0; i < sorteddata.length; ++i) {
        if (
          sorteddata[i].attributes.withdrawal_fee !== null &&
          sorteddata[i].attributes.withdrawal_fee > 100
        ) {
          labels.push(sorteddata[i].attributes.name);
          values.push(sorteddata[i].attributes.withdrawal_fee);
          if (sorteddata[i].attributes.withdrawal_fee <= 100) {
            colors.push('#0074c2');
          } else if (sorteddata[i].attributes.withdrawal_fee <= 1000) {
            colors.push('#0d1014');
          } else {
            colors.push('#af0707');
          }
        }
      }
      return { labels: labels, values: values, colors: colors };
    };
    setLabels(GetLabels(data));
    const GetHighlights = (data) => {
      const labels = [];
      const values = [];
      const colors = [];
      let sorteddata = data.map((x) => x);
      sorteddata.sort((a, b) =>
        a.attributes.name.localeCompare(b.attributes.name)
      );
      sorteddata.sort(function (a, b) {
        return a.attributes.withdrawal_fee - b.attributes.withdrawal_fee;
      });
      for (let i = 0; i < sorteddata.length; ++i) {
        if (
          sorteddata[i].attributes.withdrawal_fee !== null &&
          sorteddata[i].attributes.withdrawal_fee <= 100
        ) {
          labels.push(sorteddata[i].attributes.name);
          if (sorteddata[i].attributes.withdrawal_fee === 0) {
            colors.push('rgb(0, 116, 194,0.1)');
            values.push(0.5);
          } else if (sorteddata[i].attributes.withdrawal_fee <= 100) {
            colors.push('#0074c2');
            values.push(sorteddata[i].attributes.withdrawal_fee);
          }
        }
      }
      return { labels: labels, values: values, colors: colors };
    };
    setHighlights(GetHighlights(data));
  }, [data]);

  return (
    <>
      <h2>Withdrawal Fee (bits)</h2>
      <div className="two-bar-chart-ctn">
        <div className="two-bar-chart-inner">
          <h4>Ideal</h4>
          <Bar options={options} data={exchangedata1} />
        </div>
        <div className="two-bar-chart-inner">
          <h4>High</h4>
          <Bar options={options} data={exchangedata2} />
        </div>
      </div>
    </>
  );
};

export default WithdrawalFee;
