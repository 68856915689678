import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Check from './images/check.png';
import X from './images/x.png';
import 'animate.css';

const AppsServicesTable = ({ data, loading, animate, strapiUrl }) => {
  const checkmark = <img src={Check} alt="yes" />;
  const x = <img src={X} alt="no" />;
  const [hover, setHover] = useState();

  return (
    <>
      {loading && (
        <div className="loading-ctn">
          <div className="loading-ctn-inner">Loading...</div>
        </div>
      )}
      {!loading && (
        <div className="list-ctn padding-right">
          <div className="list-ctn-inner">
            <div
              className={`list-header-ctn ${
                animate ? 'animate__animated animate__fadeIn' : ''
              }`}
              style={{ animationDelay: '0.5s' }}
            >
              <h2>Apps/Services</h2>
              <div className="list-header-ctn-inner-APPS">
                {/* <div className="list-label-ctn">
                  <div
                    className="list-label-background"
                    style={{ backgroundColor: hover === 1 ? '#273452' : '' }}
                  />
                  <div className="list-label">Using eCash Brand</div>
                </div> */}
                <div className="list-label-ctn">
                  <div
                    className="list-label-background"
                    style={{ backgroundColor: hover === 2 ? '#273452' : '' }}
                  />
                  <div className="list-label">eCash address format</div>
                </div>
                <div className="list-label-ctn">
                  <div
                    className="list-label-background"
                    style={{ backgroundColor: hover === 3 ? '#273452' : '' }}
                  />
                  <div className="list-label">Decimal Place</div>
                </div>
                <div className="list-label-ctn">
                  <div
                    className="list-label-background"
                    style={{ backgroundColor: hover === 4 ? '#273452' : '' }}
                  />
                  <div className="list-label">Free of Other Issues</div>
                </div>
              </div>
            </div>
            {data.map((exchange, index) => {
              return (
                <div
                  key={index}
                  className={`row-ctn ${
                    animate ? 'animate__animated animate__fadeInUp' : ''
                  }`}
                  style={{ animationDelay: index * 0.05 + 's' }}
                >
                  <div
                    className="rank-col"
                    style={
                      exchange.attributes.score >= 80
                        ? { backgroundColor: '#0074C2' }
                        : exchange.attributes.score >= 60
                        ? { backgroundColor: '#005792' }
                        : exchange.attributes.score >= 40
                        ? { backgroundColor: '#00426e' }
                        : exchange.attributes.score >= 20
                        ? { backgroundColor: '#003253' }
                        : exchange.attributes.score >= 0
                        ? { backgroundColor: '#00263e' }
                        : { backgroundColor: '#001d2f' }
                    }
                  >
                    {exchange.attributes.score.toFixed()}
                  </div>
                  <Link
                    to={`/service/${exchange.attributes.name
                      .toLowerCase()
                      .replace(/\s/g, '-')
                      .replace(/\./g, '-')}`}
                    className="logo-col"
                  >
                    <img
                      src={
                        strapiUrl +
                        exchange.attributes.logo.data[0].attributes.url
                      }
                      alt={exchange.attributes.name}
                    />
                  </Link>

                  {/************************** Using eCash Brand *****************************/}

                  {/* <div
                    onMouseEnter={() => setHover(1)}
                    onMouseLeave={() => setHover(0)}
                    className="check-col"
                    style={{
                      backgroundColor:
                        exchange.attributes.ecash_brand === true
                          ? '#0074c2'
                          : exchange.attributes.ecash_brand === null
                          ? null
                          : '#af0707',
                    }}
                  >
                    {CheckTrue(exchange.attributes.ecash_brand)}
                  </div> */}

                  {/************************** ADDRESS FORMAT *****************************/}

                  <div
                    onMouseEnter={() => setHover(2)}
                    onMouseLeave={() => setHover(0)}
                    className="check-col"
                    style={{
                      backgroundColor:
                        exchange.attributes.ecash_address_format === true
                          ? '#0074c2'
                          : exchange.attributes.ecasht_address_format === null
                          ? null
                          : '#af0707',
                    }}
                  >
                    {exchange.attributes.ecash_address_format === true
                      ? checkmark
                      : exchange.attributes.ecash_address_format !== null
                      ? x
                      : '?'}
                  </div>

                  {/************************** decimal_places *****************************/}

                  <div
                    onMouseEnter={() => setHover(3)}
                    onMouseLeave={() => setHover(0)}
                    className="check-col decimal-col"
                    style={{
                      backgroundColor:
                        exchange.attributes.decimal_place === 2
                          ? '#0074c2'
                          : exchange.attributes.decimal_place !== null
                          ? '#0d1014'
                          : null,
                    }}
                  >
                    {exchange.attributes.decimal_place}
                    <span>
                      {exchange.attributes.decimal_place === null && '?'}
                    </span>
                  </div>

                  {/************************** ISSUE FREE *****************************/}

                  <div
                    onMouseEnter={() => setHover(4)}
                    onMouseLeave={() => setHover(0)}
                    className="check-col"
                    style={{
                      backgroundColor:
                        exchange.attributes.issues === null ||
                        exchange.attributes.issues === ''
                          ? '#0074c2'
                          : exchange.attributes.issues === 'unknown'
                          ? null
                          : '#af0707',
                    }}
                  >
                    {exchange.attributes.issues === null ||
                    exchange.attributes.issues === ''
                      ? checkmark
                      : x}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default AppsServicesTable;
